<template>
    <v-snackbar v-model="isActive" :timeout="timeout" :color="color" top>
        {{ message }}
    </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
    // A little scnackbar to show some feedback for the user.
    // It can be triggert with a store commit message.
    // this.$store.commit("showUserFeedback", userFeedback);
    // Params:
    // userFeedback = {
    //     color: string, e.g. "info", "success", "warn", "error", "primary", ...
    //     message: string, e.g. "Hello World!"
    //     timeout: integer, e.g. 2000 milliseconds
    // }
    name: "UserFeedback",

    computed: {
        ...mapState("userFeedback", ["color", "message", "timeout"]),
        isActive: {
            get() {
                return this.$store.state.userFeedback.isActive;
            },
            set(value) {
                this.$store.dispatch("userFeedback/setIsUserFeedback", value);
            },
        },
    },
};
</script>

<style>
</style>